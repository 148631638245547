<template>
  <base-material-card
    class="v-card-profile"
    :avatar="currentUser.picture.x"
    flat
    avatarnormal
  >
    <v-card-text class="text-center">
      <h4 class="display-2 font-weight-light mb-3 black--text">
        {{ currentUser.username }}
      </h4>
      <h6 class="display-1 mb-5 wee-text-muted">
        {{ currentUser.rolesText }}
      </h6>
      <!-- <v-btn
        color="success"
        rounded
        class="mr-0"
      >
        Follow
      </v-btn> -->
      <validation-observer
        ref="form"
        v-slot="{ handleSubmit, reset }"
      >

        <form
          @submit.prevent="handleSubmit(onUploadAvatar)"
          @reset.prevent="reset"
        >
          <v-container>
            <v-row>

              <v-col
                cols="12"
                sm="12"
              >
                <v-subheader>{{$t('base.changeAvatar')}}</v-subheader>
                <v-file-input
                  v-model="avartarFile"
                  prepend-icon="mdi-camera"
                  show-size
                  :rules="rulesAvatar"
                  accept="image/*"
                  :label="$t('base.imgChoose')"
                  :disabled="processing"
                 />
              </v-col>
              <v-col
                cols="12"
                class="mt-6"
                align="center"
              >
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="processing"
                  :loading="processing"
                >
                  <v-icon class="mr-2">mdi-cloud-upload</v-icon> {{ $t("base.okay") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </validation-observer>
    </v-card-text>
  </base-material-card>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import { defineComponent, reactive, ref } from "@vue/composition-api";
export default defineComponent({
  props: {
    value: Object,
    processing: {
      type: Boolean,
      Default: false
    }
  },
  setup(props, { emit, root }) {
    const { $i18n } = root;
    const currentUser = reactive(props.value);
    const rulesAvatar = ref([
      value =>
        !value || value.size < 2000000 || $i18n.t("error.limitImage", [2])
    ]);
    const avartarFile = ref(null);
    const onUploadAvatar = async () => {
      if (!avartarFile.value) {
        return;
      }
      // const file = self.$refs.file.files[0];
      let formData = new FormData();
      formData.append("imgUpload", avartarFile.value);
      emit("upload-avatar", formData);
    };
    return {
      currentUser,
      rulesAvatar,
      avartarFile,
      onUploadAvatar
    };
  }
});
</script>